import React, { FC, useEffect, useState } from "react";
import { WTAQuizChangeHandler, WTAQuizQuestion } from "../../../types/webtoapp.types";
import { Image } from "../Image/Image";
import { Description } from "../Description/Description";
import { Heading } from "../Heading/Heading";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './LikeDislike.scss';
import { AnimatePresence, motion } from "framer-motion";
import { ThumbsDown, ThumbsUp } from "../../shared/icons";

type Props = {
	questions: WTAQuizQuestion[];
	onChange: WTAQuizChangeHandler;
};

export const LikeDislike: FC<Props> = ({ questions, onChange }) => {
	useStyles(s);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
	const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
	const [direction, setDirection] = useState<'left' | 'right'>();
	const [activeResult, setActiveResult] = useState<'like' | 'dislike' | null>(null);


	const onClick = (result: 'like' | 'dislike') => {
		setDirection(result === 'like' ? 'right' : 'left');
		setActiveResult(result);
		setTimeout(() => {
			setIsTransitioning(true);
		}, 0);
	};

	useEffect(() => {
		if (isTransitioning && activeResult) {
			const timeout = setTimeout(() => {
				if (currentQuestionIndex < questions.length) {
					const nextIndex = currentQuestionIndex + 1;
					onChange(questions[currentQuestionIndex], activeResult, nextIndex >= questions.length);
					setCurrentQuestionIndex(nextIndex);
				}
				setIsTransitioning(false);
				setActiveResult(null);
				clearTimeout(timeout);
			}, 400);
		}
	}, [isTransitioning, activeResult, currentQuestionIndex, onChange, questions]);

	const currentQuestion = questions ? questions[currentQuestionIndex] : null;

	const variants = {
		initial: { opacity: 0, x: 0 },
		enter: { opacity: 1, x: 0 },
		exit: {
			opacity: 0,
			x: direction === 'right' ? 400 : -400,
			y: 50,
			rotate: direction === 'right' ? 45 : -45,
			transition: { duration: 0.4, ease: "easeInOut" }
		},
	};

	return <div className="wta-like-dislike">
		<AnimatePresence mode="wait">
			{currentQuestion && !isTransitioning && (
				<motion.div
					style={{
						height: '330px',
						display: 'flex',
						flexDirection: "column"
					}}
					key={currentQuestion.id}
					initial="initial"
					animate="enter"
					exit="exit"
					variants={variants}
					transition={{ duration: 0.4 }}
				>
					<div className="wta-like-dislike-image-container">
						<Image style={{
							width: 'auto',
							margin: '0 auto',
							maxWidth: '100%'
						}} src={currentQuestion.imageUrl} />
					</div>
					<div className="wta-like-dislike-details">
						<Heading locKey={currentQuestion.question} level={4} style={{ paddingBottom: '6px', textAlign: 'center' }} />
						{currentQuestion.description && <Description locKey={currentQuestion.description} style={{
							fontSize: '14px',
							color: '#313132'
						}} />}
					</div>
				</motion.div>
			)}
		</AnimatePresence>
		<div className="wta-like-dislike-buttons">
			<button
				className="wta-like-dislike-button"
				onClick={() => onClick('dislike')}
				disabled={isTransitioning}
			>
				NO
				<span className="wta-like-dislike-icon"><ThumbsDown /></span>
			</button>
			<button
				className="wta-like-dislike-button"
				onClick={() => onClick('like')}
				disabled={isTransitioning}
			>
				YES
				<span className="wta-like-dislike-icon"><ThumbsUp /></span>
			</button>
		</div>
	</div>;
};