import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Loader.scss';
import { i18n } from "../../../../../symphony";
import { Animation } from "../Animation/Animation";

type Props = {
	messageLocKey: string;
};

export const Loader: FC<Props> = ({ messageLocKey }) => {
	useStyles(s);
	return <div className='wta-loading'>
		<div className="wta-loading-icon">
			<Animation animationName="kodieLoading" style={{}} />
		</div>
		<span className="wta-loading-text">{i18n.t(messageLocKey)}</span>
		<div className="wta-loading-ellipsis-loader">
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>;
};