import React, { FC, useEffect, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './SingleChoiceList.scss';
import { i18n } from "../../../../../symphony";
import { WTAPageOption, WTASingleChoiceChangeHandler } from "../../../types/webtoapp.types";

type Props = {
	options: WTAPageOption[];
	selectedValue?: number;
	shuffledOptionIds?: number[];
	onChange?: WTASingleChoiceChangeHandler;
};

export const SingleChoiceList: FC<Props> = ({ options, selectedValue, shuffledOptionIds = [], onChange }) => {
	useStyles(s);
	const [selected, setSelected] = useState<number>(selectedValue || null);
	const [shuffledOptions, setShuffledOptions] = useState<WTAPageOption[]>();

	const onOptionSelect = (option: WTAPageOption) => {
		if (option.answerTypeId === selected) return;
		setSelected(option.answerTypeId);
		onChange && onChange(option);
	};

	const shuffleArray = (array: any[]): any[] => {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	};

	useEffect(() => {
		if (options && shuffledOptionIds) {
			if (shuffledOptionIds.length) {
				const positionsToShuffle = options.map((option, index) => {
					return shuffledOptionIds.includes(option.id) ? index : null;
				}).filter(index => index !== null);
				const valuesToShuffle = positionsToShuffle.map(index => options[index]);
				const shuffledValues = shuffleArray(valuesToShuffle);
				const result = [...options];
				positionsToShuffle.forEach((position, index) => {
					result[position] = shuffledValues[index];
				});
				setShuffledOptions(result);
			} else {
				setShuffledOptions(options);
			}
		}
	}, [options, shuffledOptionIds]);

	return <div className="wta-list">
		{shuffledOptions && shuffledOptions.map(o => (<div
			onClick={() => { onOptionSelect(o); }}
			className={`wta-option ${selected === o.answerTypeId ? 'selected' : ''}`}
			key={o.value}
		>
			{i18n.t(o.label)}
		</div>))}
	</div>;
};