import { useEffect, useState } from 'react';
import {
	catchError, of, take, tap,
} from 'rxjs';
import { Container } from '../../../symphony';
import { ExperimentApi, IResolveExperimentResponse } from '../../../api/public/experiment-api';

const experimentData: Record<string, IResolveExperimentResponse> = {};
const loadingResolvers: Record<string, Promise<boolean>> = {};

export function prefetchExperimentData(
	experimentKey: string,
): Promise<boolean> {
	const experimentApi = Container.take('global', ExperimentApi);

	if (experimentData[experimentKey]) {
		return Promise.resolve(true);
	}

	if (!loadingResolvers[experimentKey]) {
		loadingResolvers[experimentKey] = new Promise<boolean>((resolve) => {
			experimentApi
				.resolveExperiment(experimentKey)
				.pipe(
					take(1),
					tap((response) => {
						experimentData[experimentKey] = response;
						resolve(true);
						delete loadingResolvers[experimentKey];
					}),
					catchError(() => {
						resolve(false);
						delete loadingResolvers[experimentKey];
						return of(null);
					}),
				)
				.subscribe();
		});
	}

	return loadingResolvers[experimentKey];
}

export async function exposeExperiment(
	experimentKey: string,
): Promise<boolean> {
	const experimentApi = Container.take('global', ExperimentApi);

	return new Promise<boolean>((resolve) => {
		experimentApi
			.exposeExperiment(experimentKey)
			.pipe(
				take(1),
				tap(() => {
					resolve(true);
				}),
				catchError(() => {
					resolve(false);
					return of(null);
				}),
			)
			.subscribe();
	});
}

export function getExperimentWithExposing(
	experimentKey: string,
): IResolveExperimentResponse | undefined {
	exposeExperiment(experimentKey);

	return experimentData[experimentKey];
}

export function getExperimentWithoutExposing(
	experimentKey: string,
): IResolveExperimentResponse | undefined {
	return experimentData[experimentKey];
}

export function useExperimentData(experimentKey: string, shouldExpose = false) {
	const [data, setData] = useState<IResolveExperimentResponse | undefined>(
		experimentData[experimentKey],
	);

	useEffect(() => {
		if (!experimentData[experimentKey]) {
			prefetchExperimentData(experimentKey).then(() => {
				setData(experimentData[experimentKey]);
				if (shouldExpose) {
					exposeExperiment(experimentKey);
				}
			});
		}
	}, [experimentKey]);

	return data;
}
