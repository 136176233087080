import { WTAJourney, WTAPage, WTAPageType } from "./types/webtoapp.types";

export const WTAGettingStartedPage: WTAPage = {
	id: 239,
	type: WTAPageType.getStarted,
	title: "wta.page.239.title",
	content: [],
	style: {},
	pageOptions: []
};

export const WTAInitializationPage: WTAPage = {
	id: 0,
	type: WTAPageType.flowInitialization,
	title: '',
	content: [],
	style: {}
};

export const WTADefaultJourney: WTAJourney = ({
	"pages": [
		//CONTROL
		{
			"id": 239,
			"type": "getStarted",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "wta.page.239.title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "pageTitle",
					"data": "wta.page.239.title",
					"style": { "flex": "60%"}
				},
				{
					"type": "awards",
					"style": { "flex": 1 }
				}
			],
			"style": {},
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},

		//TEST 1 (AI OR HUMAN)
		// {
		// 	"id": 239,
		// 	"type": "aiOrHuman",
		// 	"pageName": "PsychoAttack_fake_path_v2",
		// 	"title": "wta.page.239.title",
		// 	"showBackButton": true,
		// 	"cta": "",
		// 	"content": [
		// 		{
		// 			"type": "h1",
		// 			"data": "wta.page.239.title",
		// 			"style": { "fontSize": "32px" }
		// 		},
		// 		{
		// 			"type": "image",
		// 			"data": "https://www.aiscribbles.com/img/variant/large-preview/20638/?v=954db4",
		// 			"style": {
		// 				"borderRadius": "16px",
		// 				"margin": "0 20px",
		// 				"width": "calc(100% - 40px)"
		// 			}
		// 		},
		// 		{
		// 			"type": "h2",
		// 			"data": "AI or Human?",
		// 			"style": { "textAlign": "left", "padding": "20px 0" }
		// 		},
		// 		{
		// 			"type": "h4",
		// 			"data": "Do you think this has been created by an AI or human?"
		// 		}
		// 	],
		// 	"style": {},
		// 	"meta": {
		// 		"navigation": {
		// 			"courseNavigation": 0,
		// 			"showAllNavigation": 1
		// 		}
		// 	},
		// 	"pageOptions": []
		// },

		//TEST 2 (ANIMATION)
		// {
		// 	"id": 239,
		// 	"type": "getStarted",
		// 	"pageName": "PsychoAttack_fake_path_v2",
		// 	"title": "wta.page.239.title",
		// 	"showBackButton": true,
		// 	"cta": "",
		// 	"content": [
		// 		{
		// 			"type": "pageTitle",
		// 			"data": "wta.page.239.title",
		// 			"style": {
		// 				"marginBottom": "20px"
		// 			}
		// 		},
		// 		{
		// 			"type": "animation",
		// 			"data": "appleAward"
		// 		}
		// 	],
		// 	"style": {},
		// 	"meta": {
		// 		"navigation": {
		// 			"courseNavigation": 0,
		// 			"showAllNavigation": 1
		// 		}
		// 	},
		// 	"pageOptions": []
		// },

		//TEST 3 (USER REVIEW)
		// {
		// 	"id": 239,
		// 	"type": "getStarted",
		// 	"pageName": "PsychoAttack_fake_path_v2",
		// 	"title": "wta.page.239.title",
		// 	"showBackButton": true,
		// 	"cta": "",
		// 	"content": [
		// 		{
		// 			"type": "image",
		// 			"data": "https://blob.sololearn.com/web-assets/wta-get-started.svg",
		// 			"style": { "padding": "0", "marginTop": "40px" }
		// 		},
		// 		{
		// 			"type": "awards",
		// 			"data": null,
		// 			"style": {
		// 				"flex": 1
		// 			}
		// 		}
		// 	],
		// 	"style": {
		// 		"paddingTop": "46px"
		// 	},
		// 	"meta": {
		// 		"navigation": {
		// 			"courseNavigation": 0,
		// 			"showAllNavigation": 1
		// 		}
		// 	},
		// 	"pageOptions": []
		// },

		{
			"id": 240,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "wta.page.240.h2"
				},
				{
					"type": "animation",
					"data": "rocket"
				}
			],
			"style": {},
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 241,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "h1",
					"data": "wta.page.241.h1",
					"style": {
						"color": "#fff"
					}
				},
				{
					"type": "paragraph",
					"data": "wta.page.241.p",
					"style": {
						"color": "#fff"
					}
				},
				{
					"type": "animation",
					"data": "kodieHint",
					"style": {
						"padding": "min(10%, 48px)"
					}
				}
			],
			"style": {},
			"meta": {
				"style": {
					"background": "purple"
				},
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 243,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "wta.page.243.h2"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": {},
			"meta": null,
			"pageOptions": [
				{
					"id": 248,
					"pageId": 243,
					"label": "wta.profession.student",
					"preselected": false,
					"value": 1,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 2,
					"answerTypeId": 159,
					"answerId": 159,
					"iconUrl": null
				},
				{
					"id": 249,
					"pageId": 243,
					"label": "wta.profession.worker",
					"preselected": false,
					"value": 2,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 2,
					"answerTypeId": 160,
					"answerId": 160,
					"iconUrl": null
				},
				{
					"id": 250,
					"pageId": 243,
					"label": "wta.profession.entrepreneur",
					"preselected": false,
					"value": 3,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 2,
					"answerTypeId": 161,
					"answerId": 161,
					"iconUrl": null
				},
				{
					"id": 251,
					"pageId": 243,
					"label": "wta.profession.other",
					"preselected": false,
					"value": 4,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 2,
					"answerTypeId": 162,
					"answerId": 162,
					"iconUrl": null
				}
			]
		},
		{
			"id": 244,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "wta.page.244.h2"
				},
				{
					"type": "animation",
					"data": "blocks"
				}
			],
			"style": {},
			"meta": null,
			"pageOptions": []
		},
		{
			"id": 245,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "wta.page.245.h2"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": {},
			"meta": null,
			"pageOptions": [
				{
					"id": 252,
					"pageId": 245,
					"label": "wta.learning-time.3-plus",
					"preselected": false,
					"value": 1,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 36,
					"answerTypeId": 1197,
					"answerId": 1197,
					"iconUrl": null
				},
				{
					"id": 253,
					"pageId": 245,
					"label": "wta.learning-time.1-3",
					"preselected": false,
					"value": 2,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 36,
					"answerTypeId": 1198,
					"answerId": 1198,
					"iconUrl": null
				},
				{
					"id": 254,
					"pageId": 245,
					"label": "wta.learning-time.under-1",
					"preselected": false,
					"value": 3,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 36,
					"answerTypeId": 1199,
					"answerId": 1199,
					"iconUrl": null
				},
				{
					"id": 255,
					"pageId": 245,
					"label": "wta.learning-time.0",
					"preselected": false,
					"value": 4,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 36,
					"answerTypeId": 2000,
					"answerId": 2000,
					"iconUrl": null
				}
			]
		},
		{
			"id": 246,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "userReview",
					"data": "wta.page.246.user-review",
					"userName": "wta.page.246.user-name"
				}
			],
			"style": {},
			"meta": {
				"style": {
					"background": "purple"
				}
			},
			"pageOptions": []
		},
		{
			"id": 247,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "wta.page.247.h2"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": {},
			"meta": null,
			"pageOptions": [
				{
					"id": 256,
					"pageId": 247,
					"label": "wta.age.under-18",
					"preselected": false,
					"value": 1,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 6,
					"answerTypeId": 88,
					"answerId": 88,
					"iconUrl": null
				},
				{
					"id": 257,
					"pageId": 247,
					"label": "wta.age.19-24",
					"preselected": false,
					"value": 2,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 6,
					"answerTypeId": 18,
					"answerId": 18,
					"iconUrl": null
				},
				{
					"id": 258,
					"pageId": 247,
					"label": "wta.age.25-34",
					"preselected": false,
					"value": 3,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 6,
					"answerTypeId": 19,
					"answerId": 19,
					"iconUrl": null
				},
				{
					"id": 259,
					"pageId": 247,
					"label": "wta.age.35-44",
					"preselected": false,
					"value": 4,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 6,
					"answerTypeId": 20,
					"answerId": 20,
					"iconUrl": null
				},
				{
					"id": 260,
					"pageId": 247,
					"label": "wta.age.45-over",
					"preselected": false,
					"value": 5,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 6,
					"answerTypeId": 21,
					"answerId": 21,
					"iconUrl": null
				}
			]
		},
		{
			"id": 248,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "wta.page.248.h2"
				},
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/teams-selling-point-5.png",
					"style": {
						"marginTop": "min(10%, 38px)",
						"padding": "min(4%, 24px)",
						"paddingTop": "0"
					}
				}
			],
			"style": {},
			"meta": null,
			"pageOptions": []
		},
		{
			"id": 249,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "userReview",
					"data": "wta.page.249.user-review",
					"userName": "wta.page.249.user-name"
				}
			],
			"style": {},
			"meta": {
				"style": {
					"background": "purple"
				}
			},
			"pageOptions": []
		},
		{
			"id": 250,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "wta.page.250.h2"
				},
				{
					"type": "animation",
					"data": "mobileScroll"
				}
			],
			"style": {},
			"meta": null,
			"pageOptions": []
		},
		{
			"id": 251,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "wta.page.251.h2"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": {},
			"meta": null,
			"pageOptions": [
				{
					"id": 261,
					"pageId": 251,
					"label": "wta.ai-purpose.upgrade-skills",
					"preselected": false,
					"value": 1,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 33,
					"answerTypeId": 220,
					"answerId": 220,
					"iconUrl": null
				},
				{
					"id": 262,
					"pageId": 251,
					"label": "wta.ai-purpose.competitive",
					"preselected": false,
					"value": 2,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 33,
					"answerTypeId": 222,
					"answerId": 222,
					"iconUrl": null
				},
				{
					"id": 263,
					"pageId": 251,
					"label": "wta.ai-purpose.efficient",
					"preselected": false,
					"value": 3,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 33,
					"answerTypeId": 221,
					"answerId": 221,
					"iconUrl": null
				},
				{
					"id": 264,
					"pageId": 251,
					"label": "wta.ai-purpose.own-project",
					"preselected": false,
					"value": 4,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 33,
					"answerTypeId": 223,
					"answerId": 223,
					"iconUrl": null
				},
				{
					"id": 265,
					"pageId": 251,
					"label": "wta.ai-purpose.curious",
					"preselected": false,
					"value": 5,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 33,
					"answerTypeId": 224,
					"answerId": 224,
					"iconUrl": null
				}
			]
		},
		{
			"id": 252,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "userReview",
					"data": "wta.page.252.user-review",
					"userName": "wta.page.252.user-name"
				}
			],
			"style": {},
			"meta": {
				"style": {
					"background": "purple"
				}
			},
			"pageOptions": []
		},
		{
			"id": 253,
			"type": "topics",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "wta.page.253.h2"
				},
				{
					"type": "multiChoiceCards",
					"data": null
				}
			],
			"style": {},
			"meta": null,
			"pageOptions": [
				{
					"id": 266,
					"pageId": 253,
					"label": "wta.topic.write-better",
					"preselected": false,
					"value": 1,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 37,
					"answerTypeId": 2001,
					"answerId": 2001,
					"iconUrl": "https://blob.sololearn.com/onboarding/write-better.svg"
				},
				{
					"id": 267,
					"pageId": 253,
					"label": "wta.topic.understand-data",
					"preselected": false,
					"value": 2,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 37,
					"answerTypeId": 2002,
					"answerId": 2002,
					"iconUrl": "https://blob.sololearn.com/onboarding/understand-data.svg"
				},
				{
					"id": 268,
					"pageId": 253,
					"label": "wta.topic.llms",
					"preselected": false,
					"value": 3,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 37,
					"answerTypeId": 2003,
					"answerId": 2003,
					"iconUrl": "https://blob.sololearn.com/onboarding/how-llms-work.svg"
				},
				{
					"id": 269,
					"pageId": 253,
					"label": "wta.topic.generate-images",
					"preselected": false,
					"value": 4,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 37,
					"answerTypeId": 2004,
					"answerId": 2004,
					"iconUrl": "https://blob.sololearn.com/onboarding/generate-images.svg"
				},
				{
					"id": 270,
					"pageId": 253,
					"label": "wta.topic.productivity",
					"preselected": false,
					"value": 5,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 37,
					"answerTypeId": 2005,
					"answerId": 2005,
					"iconUrl": "https://blob.sololearn.com/onboarding/improve-productivity.svg"
				},
				{
					"id": 271,
					"pageId": 253,
					"label": "wta.topic.brainstorm",
					"preselected": false,
					"value": 6,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 37,
					"answerTypeId": 2006,
					"answerId": 2006,
					"iconUrl": "https://blob.sololearn.com/onboarding/brainstorm-ai.svg"
				}
			]
		},
		{
			"id": 254,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "wta.page.254.h2"
				},
				{
					"type": "image",
					"data": "https://blob.sololearn.com/onboarding/help-learn.gif",
					"style": {
						"paddingTop": "0",
						"paddingBottom": "0",
						"paddingRight": "min(10%, 48px)",
						"paddingLeft": "min(10%, 48px)"
					}
				}
			],
			"style": {},
			"meta": null,
			"pageOptions": []
		},
		{
			"id": 255,
			"type": "quiz",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "wta.page.255.h2"
				},
				{
					"type": "likeDislike",
					"data": "",
					"questions": [
						{
							"id": 1,
							"imageUrl": "https://blob.sololearn.com/onboarding/writing-emails.svg",
							"question": "wta.quiz.question.1.title",
							"description": null,
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2001,
								2005
							]
						},
						{
							"id": 2,
							"imageUrl": "https://blob.sololearn.com/onboarding/business-writing.svg",
							"question": "wta.quiz.question.2.title",
							"description": "wta.quiz.question.2.description",
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2001
							]
						},
						{
							"id": 3,
							"imageUrl": "https://blob.sololearn.com/onboarding/creative-writing.svg",
							"question": "wta.quiz.question.3.title",
							"description": "wta.quiz.question.3.description",
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2001,
								2005
							]
						},
						{
							"id": 4,
							"imageUrl": "https://blob.sololearn.com/onboarding/social-media-writing.svg",
							"question": "wta.quiz.question.4.title",
							"description": null,
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2001,
								2005
							]
						},
						{
							"id": 5,
							"imageUrl": "https://blob.sololearn.com/onboarding/marketing-materials-writing.svg",
							"question": "wta.quiz.question.5.title",
							"description": null,
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2001,
								2005
							]
						},
						{
							"id": 6,
							"imageUrl": "https://blob.sololearn.com/onboarding/data-visualization.svg",
							"question": "wta.quiz.question.6.title",
							"description": null,
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2002,
								2005
							]
						},
						{
							"id": 7,
							"imageUrl": "https://blob.sololearn.com/onboarding/data-inerpretation.svg",
							"question": "wta.quiz.question.7.title",
							"description": null,
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2002
							]
						},
						{
							"id": 8,
							"imageUrl": "https://blob.sololearn.com/onboarding/data-segmentation.svg",
							"question": "wta.quiz.question.8.title",
							"description": null,
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2002
							]
						},
						{
							"id": 9,
							"imageUrl": "https://blob.sololearn.com/onboarding/data-analysis.svg",
							"question": "wta.quiz.question.9.title",
							"description": null,
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2002,
								2005
							]
						},
						{
							"id": 10,
							"imageUrl": "https://blob.sololearn.com/onboarding/generating-marketing-creatives.svg",
							"question": "wta.quiz.question.10.title",
							"description": null,
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2004,
								2005
							]
						},
						{
							"id": 11,
							"imageUrl": "https://blob.sololearn.com/onboarding/creatives-for-paid.svg",
							"question": "wta.quiz.question.11.title",
							"description": null,
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2004,
								2005
							]
						},
						{
							"id": 12,
							"imageUrl": "https://blob.sololearn.com/onboarding/images-for-social-media.svg",
							"question": "wta.quiz.question.12.title",
							"description": null,
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2004,
								2005
							]
						},
						{
							"id": 13,
							"imageUrl": "https://blob.sololearn.com/onboarding/how-gen-ai-works.svg",
							"question": "wta.quiz.question.13.title",
							"description": null,
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2003,
								2006
							]
						},
						{
							"id": 14,
							"imageUrl": "https://blob.sololearn.com/onboarding/ai-ethics.svg",
							"question": "wta.quiz.question.14.title",
							"description": null,
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2003
							]
						},
						{
							"id": 15,
							"imageUrl": "https://blob.sololearn.com/onboarding/ai-prompting.svg",
							"question": "wta.quiz.question.15.title",
							"description": null,
							"aliases": [
								"genai-in-practice"
							],
							"topicIds": [
								2003,
								2006,
								2005
							]
						}
					]
				}
			],
			"style": {},
			"meta": null,
			"pageOptions": []
		},
		{
			"id": 256,
			"type": "loading",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": [
				{
					"type": "loader",
					"data": "wta.page.256.loader",
					"animation": "KODIE"
				}
			],
			"style": {},
			"meta": null,
			"pageOptions": []
		},
		{
			"id": 7,
			"type": "paywall",
			"pageName": "GET-PRO-PSYCHO-ATTACK",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": null,
			"style": null,
			"meta": {
				"touchPoint": "onboarding"
			},
			"pageOptions": []
		},
		{
			"id": 257,
			"type": "emailUpdate",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": null,
			"style": {},
			"meta": null,
			"pageOptions": []
		},
		{
			"id": 258,
			"type": "completeAccountRegistration",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": null,
			"style": {},
			"meta": null,
			"pageOptions": []
		},
		{
			"id": 259,
			"type": "downloadApp",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "title",
			"showBackButton": true,
			"cta": "",
			"content": null,
			"style": {},
			"meta": null,
			"pageOptions": []
		}
	],
	"flow": {
		"flowId": 38,
		"startPageId": 239,
		"name": null,
		"selections": [
			{
				"screenId": 240,
				"selection": "239_0"
			},
			{
				"screenId": 241,
				"selection": "239_0|240_0"
			},
			{
				"screenId": 243,
				"selection": "239_0|240_0|241_0"
			},
			{
				"screenId": 244,
				"selection": "239_0|240_0|241_0|243_1"
			},
			{
				"screenId": 245,
				"selection": "239_0|240_0|241_0|243_1|244_0"
			},
			{
				"screenId": 246,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1"
			},
			{
				"screenId": 247,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0"
			},
			{
				"screenId": 248,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1"
			},
			{
				"screenId": 249,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1|248_0"
			},
			{
				"screenId": 250,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1|248_0|249_0"
			},
			{
				"screenId": 251,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1|248_0|249_0|250_0"
			},
			{
				"screenId": 252,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1|248_0|249_0|250_0|251_1"
			},
			{
				"screenId": 253,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1|248_0|249_0|250_0|251_1|252_0"
			},
			{
				"screenId": 254,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1|248_0|249_0|250_0|251_1|252_0|253_1"
			},
			{
				"screenId": 255,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1|248_0|249_0|250_0|251_1|252_0|253_1|254_0"
			},
			{
				"screenId": 256,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1|248_0|249_0|250_0|251_1|252_0|253_1|254_0|255_0"
			},
			{
				"screenId": 257,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1|248_0|249_0|250_0|251_1|252_0|253_1|254_0|255_0|256_0"
			},
			{
				"screenId": 7,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1|248_0|249_0|250_0|251_1|252_0|253_1|254_0|255_0|256_0|257_0"
			},
			{
				"screenId": 258,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1|248_0|249_0|250_0|251_1|252_0|253_1|254_0|255_0|256_0|257_0|7_0"
			},
			{
				"screenId": 259,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1|248_0|249_0|250_0|251_1|252_0|253_1|254_0|255_0|256_0|257_0|7_0|258_0"
			},
			{
				"screenId": null,
				"selection": "239_0|240_0|241_0|243_1|244_0|245_1|246_0|247_1|248_0|249_0|250_0|251_1|252_0|253_1|254_0|255_0|256_0|257_0|7_0|258_0|259_0"
			}
		]
	},
	"options": {}
} as WTAJourney);