import React, { FC, useEffect } from "react";
import { WTAPage } from "../../../types/webtoapp.types";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTAGetStartedPage.scss';
import { Container } from "../../../../../symphony";
import { WebToAppService } from "../../../services/webtoapp.service";
import { Padding } from "../../shared/Padding/Padding";
import { SlIcon } from "@sololearnorg/icons";
import { WTAComponentDrawer } from "../../pageComponents/WTAComponentDrawer/WTAComponentDrawer";

type Props = {
	page: WTAPage;
};

export const WTAGetStartedPage: FC<Props> = ({ page }) => {
	useStyles(s);

	const { footer, progressBar } = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.getStarted();
		progressBar.hide();
	}, []);

	return <div className="wta-get-started" style={page.style}>
		<Padding>
			<div className="wta-get-started-header">
				<div className="wta-get-started-logo"><SlIcon iconId="sololearn" /></div>
			</div>
		</Padding>
		<div className="wta-get-started-body">
			<WTAComponentDrawer
				components={page.content}
				pageOptions={page.pageOptions}
			/>
		</div>
	</div>;
};