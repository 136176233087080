import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTAButton.scss';
import { i18n } from "../../../../../symphony";

type Props = {
	onClick: () => void;
	background?: 'purple' | 'white';
	locKey?: string;
	disabled?: boolean;
	loading?: boolean;
	className?: string;
	children?: React.ReactNode;
};

export const WTAButton: FC<Props> = ({
	onClick,
	disabled,
	className,
	background = 'purple',
	locKey = 'continue',
	loading = false,
	children = null

}) => {
	useStyles(s);

	return <button
		disabled={disabled}
		className={`wta-button ${background} ${loading ? 'loading' : ''} ${className || ''}`}
		onClick={onClick}
	>
		{children ? children : i18n.t(locKey)}
	</button>;
};
