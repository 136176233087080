import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PageTitle.scss';
import { SlIcon } from "@sololearnorg/icons";
import { i18n } from "../../../../../symphony";

type Props = {
	titleKey: string;
	style: React.CSSProperties;
};

export const PageTitle: FC<Props> = ({ titleKey, style }) => {
	useStyles(s);
	return <div className="wta-page-title-wrapper" style={style}>
		<div className="wta-page-title-icon">
			<SlIcon iconId="purpleDots" />
		</div>
		<h1 className="wta-page-title-headline">{i18n.t(titleKey)}</h1>
		<div className="wta-page-title-icon">
			<SlIcon iconId="purpleDots" />
		</div>
	</div>;
};