export type WTAJourney = {
	flow: WTAFlow;
	pages: WTAPage[];
};

export type WTAFlow = {
	startPageId: number;
	flowId: number;
	selections: WTASelection[];
};

export type WTASelection = {
	screenId: number;
	selection: string;
};

export type WTAPage = {
	id?: number;
	meta?: WTAPageMeta;
	type: WTAPageType;
	content?: WTAComponent[];
	pageOptions?: WTAPageOption[];
	title?: string;
	showBackButton?: boolean;
	cta?: string;
	pageName?: string;
	style?: any;
};

export enum WTAPageType {
	generic = 'generic',
	paywall = 'paywall',
	singleChoice = 'singleChoice',
	quiz = 'quiz',
	topics = 'topics',
	loading = 'loading',
	getStarted = 'getStarted',
	emailUpdate = 'emailUpdate',
	completeAccountRegistration = 'completeAccountRegistration',
	downloadApp = 'downloadApp',
	flowInitialization = 'flowInitialization',
	aiOrHuman = 'aiOrHuman'
}

export type WTAComponent = {
	type: WTAComponentType;
	data?: string;
	userName?: string;
	style?: React.CSSProperties;
	questions?: WTAQuizQuestion[];
	animation?: 'KODIE';
	testimonialKeys?: string[];
	shuffledOptionIds?: number[];
};

export enum WTAComponentType {
	h1 = 'h1',
	h2 = 'h2',
	h3 = 'h3',
	h4 = 'h4',
	paragraph = 'paragraph',
	image = 'image',
	animation = 'animation',
	singleChoiceList = 'singleChoiceList',
	multiChoiceCards = 'multiChoiceCards',
	userReview = 'userReview',
	likeDislike = 'likeDislike',
	loader = 'loader',
	svgIcon = 'svgIcon',
	rive = 'rive',
	pageTitle = 'pageTitle',
	testimonials = 'testimonials',
	awards = 'awards'
}

export type WTAPageOption = {
	questionTypeId?: number;
	answerTypeId?: number;
	iconUrl?: string;
	id?: number;
	label: string;
	legend?: string;
	navigation?: number;
	pageId?: number;
	preselected?: boolean;
	value: number;
};

export type WTAPageMeta = {
	style?: {
		background: 'white' | 'purple';
	};
};

export type WTAQuizQuestion = {
	id: number;
	imageUrl: string;
	question: string;
	description: string;
	aliases: string[];
	topicIds: number[];
};

export type WTAQuizChangeHandler = (
	question: WTAQuizQuestion,
	result: 'like' | 'dislike',
	isCompleted: boolean
) => void;

export enum WTAAIorHumanAnswers {
	ai = 1,
	human = 2
}

export type WTASingleChoiceChangeHandler = (option: WTAPageOption) => void;

export type WTAMultiChoiceChangeHandler = (topics: WTAPageOption[]) => void;