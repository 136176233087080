import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Awards.scss';
import { SlIcon } from "@sololearnorg/icons";
import { Padding } from "../../shared/Padding/Padding";

type Props = {
	style: React.CSSProperties;
};

export const Awards: FC<Props> = ({
	style
}) => {
	useStyles(s);
	return <Padding style={style} className="wta-awards">
		<div className="wta-awards-award">
			<SlIcon iconId="appleGenerativeAward" />
		</div>
		<div className="wta-awards-award">
			<SlIcon iconId="googlePlayEditorsChoice" />
		</div>
	</Padding>;
};