import React, { FC } from "react";
import { WTAComponent, WTAComponentType, WTAPageOption, WTAQuizChangeHandler, WTAQuizQuestion, WTASingleChoiceChangeHandler, WTAMultiChoiceChangeHandler } from "../../../types/webtoapp.types";
import { Padding } from "../../shared/Padding/Padding";
import { Heading } from "../Heading/Heading";
import { Image } from "../Image/Image";
import { Animation } from "../Animation/Animation";
import { SingleChoiceList } from "../SingleChoiceList/SingleChoiceList";
import { UserReview } from "../UserReview/UserReview";
import { LikeDislike } from "../LikeDislike/LikeDislike";
import { Description } from "../Description/Description";
import { MultiChoiceCards } from "../MultiChoiceCards/MultiChoiceCards";
import { Loader } from "../Loader/Loader";
import { SvgIcon } from "../SvgIcon/SvgIcon";
import { RiveLoader } from "../RiveLoader/RiveLoader";
import { PageTitle } from "../PageTitle/PageTitle";
import { Testimonials } from "../Testimonials/Testimonials";
import { Awards } from "../Awards/Awards";

const HeadingLevel = {
	[WTAComponentType.h1]: 1,
	[WTAComponentType.h2]: 2,
	[WTAComponentType.h3]: 3,
	[WTAComponentType.h4]: 4
};

type Props = {
	components: WTAComponent[];
	pageOptions: WTAPageOption[];
	quizQuestions?: WTAQuizQuestion[];
	handlers?: {
		onSingleChoiceListChange?: WTASingleChoiceChangeHandler;
		onMultiChoiceChange?: WTAMultiChoiceChangeHandler;
		onQuizChange?: WTAQuizChangeHandler;
	};
};

export const WTAComponentDrawer: FC<Props> = ({
	components,
	pageOptions,
	handlers,
	quizQuestions
}) => {
	const renderComponent = (component: WTAComponent, key: string) => {
		switch (component.type) {
			case WTAComponentType.h1:
			case WTAComponentType.h2:
			case WTAComponentType.h3:
			case WTAComponentType.h4:
				return <Padding key={key}><Heading
					style={component.style || {}}
					level={HeadingLevel[component.type]}
					locKey={component.data}
				/></Padding>;
			case WTAComponentType.paragraph:
				return <Padding key={key}><Description
					locKey={component.data}
					style={component.style || {}}
				/></Padding>;
			case WTAComponentType.image:
				return <Image key={key} src={component.data} style={component.style || {}} />;
			case WTAComponentType.animation:
				return <Animation key={key} style={component.style || {}} animationName={component.data} />;
			case WTAComponentType.singleChoiceList:
				return <Padding key={key}>
					<SingleChoiceList
						options={pageOptions}
						shuffledOptionIds={component.shuffledOptionIds}
						onChange={handlers?.onSingleChoiceListChange}
					/>
				</Padding>;
			case WTAComponentType.multiChoiceCards:
				return <Padding key={key}>
					<MultiChoiceCards
						options={pageOptions}
						onChange={handlers?.onMultiChoiceChange}
					/>
				</Padding>;
			case WTAComponentType.userReview:
				return <UserReview
					key={key}
					review={component.data}
					userName={component.userName}
					style={component.style}
				/>;
			case WTAComponentType.likeDislike:
				return <Padding key={key}><LikeDislike
					questions={quizQuestions}
					onChange={handlers?.onQuizChange}
				/></Padding>;
			case WTAComponentType.loader:
				return <Loader key={key} messageLocKey={component.data} />;
			case WTAComponentType.svgIcon:
				return <Padding key={key}>
					<SvgIcon
						iconId={component.data}
						style={component.style || {}}
					/>
				</Padding>;
			case WTAComponentType.rive:
				return <RiveLoader key={key} />;
			case WTAComponentType.pageTitle:
				return <PageTitle key={key} titleKey={component.data} style={component.style} />;
			case WTAComponentType.testimonials:
				return <Testimonials key={key} testimonialKeys={component.testimonialKeys} />;
			case WTAComponentType.awards:
				return <Awards key={key} style={component.style} />;
			default:
				return null;
		}
	};

	return <>{components.map((c, index) => renderComponent(c, index.toString()))}</>;
};
