import { AxiosResponse } from 'axios';
import { Axios } from 'axios-observable';
import {
	Observable, of, Subject, timer,
} from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	map,
	mergeMap,
	shareReplay,
	take,
} from 'rxjs/operators';

import { IApiUrls, IResponse } from './global.interface';
import { ITokenData, Service, Container } from '../../symphony';

export interface ITokenDataResponse extends IResponse {
	data: ITokenData;
}

const refreshTokenStream: {
	[key: string]: Observable<ITokenData>;
} = {};

declare const grecaptcha;

const featureToggling = {
	publicTokenCaptcha: {
		state: 'enabled',
	},
}; // Todo this is temporary solution

let attempts = 0;
function pollForRecaptcha(callback) {
	if ((window as any).grecaptcha && (window as any).grecaptcha.enterprise) {
		callback();
	} else if (attempts < 500) {
		setTimeout(() => pollForRecaptcha(callback), 100);
		attempts++;
	}
}

@Service()
export class TokenApi {
	private captcha$ = new Subject<{
		token: string;
		isChallengeBasedToken: boolean;
	}>();

	private lastCaptchaToken: string;

	private tokenRequest: Observable<ITokenData>;

	public getPublicToken(newToken?: string): Observable<ITokenData> {
		if (typeof window === 'undefined') {
			const ssrToken = Container.take('global', 'jwtAuthPublicToken');
			if (ssrToken) {
				return timer(1).pipe(
					mergeMap(() => of({
						accessToken: ssrToken,
						refreshToken: ssrToken,
						expiresIn: new Date().getTime() / 1000 + 3480,
					} as ITokenData)),
				);
			}
			throw Error('No SSR token in container');
		}

		if (featureToggling.publicTokenCaptcha.state === 'enabled') {
			if (typeof window !== undefined && window.location.pathname.includes('/wta')) {
				setTimeout(() => {
					this.captcha$.next({
						token: 'token',
						isChallengeBasedToken: true
					});
				});
			} else if (newToken) {
				setTimeout(() => {
					this.captcha$.next({
						token: newToken,
						isChallengeBasedToken: true,
					});
				});
			} else {
				pollForRecaptcha(() => {
					grecaptcha.enterprise.ready(() => {
						grecaptcha.enterprise
							.execute(
								Container.take(
									'global',
									'publicTokenCaptchaKey',
								),
								{
									action: 'publicToken',
								},
							)
							.then((token: string) => {
								this.captcha$.next({
									token,
									isChallengeBasedToken: false,
								});
							});
					});
				});
			}

			return this.captcha$.pipe(
				filter((a) => !!a),
				take(1),
				concatMap(({ token, isChallengeBasedToken }) => this.handleRequest(token, isChallengeBasedToken)),
			);
		}
		return this.handleRequest(null);
	}

	public refreshToken(refreshToken: string): Observable<ITokenData> {
		if (!refreshTokenStream[refreshToken]) {
			refreshTokenStream[refreshToken] = Axios.post(
				`${(Container.take('global', 'envUrl') as IApiUrls)
					.authenticationApiHost
				}/token:refresh`,
				refreshToken,
				{ headers: { 'content-type': 'application/json' } },
			).pipe(
				map((res: AxiosResponse) => res.data),
				shareReplay({ refCount: true }),
			);
		}
		return refreshTokenStream[refreshToken];
	}

	private handleRequest(
		captchaToken: string,
		isChallengeAcceptedToken = false,
	): Observable<ITokenData> {
		if (captchaToken === this.lastCaptchaToken && this.tokenRequest) {
			return this.tokenRequest;
		}

		this.lastCaptchaToken = captchaToken;
		let campaignId: string = null;
		if (typeof window !== undefined && window.location.pathname.includes('/wta')) {
			campaignId = 'w2a';
		}
		this.tokenRequest = Axios.post(
			`${Container.take('global', 'authApiHost')}/user/publicToken`,
			{
				subject: Container.take('global', 'guid'),
				checkboxCaptcha: isChallengeAcceptedToken,
				...(captchaToken && {
					captchaToken,
				}),
			},
			{
				headers: { 'content-type': 'application/json' },
				params: campaignId ? { campaignId } : {}
			}
		).pipe(
			take(1),
			shareReplay(1),
			map((res: AxiosResponse) => res?.data),
			catchError(() => of(null)),
		);
		return this.tokenRequest;
	}
}
