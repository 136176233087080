import { firstValueFrom } from 'rxjs';
import { DataTrackingApi } from '../../../api/public/data-tracking';
import { AuthService, Container, IBaseDataTracking, Service, TrackingEventNames, TrackingService } from '../../../symphony';
import { v4 as uuidv4 } from 'uuid';

declare const fbq: (
	trackCustom: 'trackCustom',
	eventName: string,
	params: any
) => void;

@Service()
export class WebToAppTrackingSerivce {
	private trackingService = Container.take('global', TrackingService);
	private dataTrackingApi = Container.take('global', DataTrackingApi);
	private authService = Container.take('global', AuthService);

	public trackFlowOpen = (queryParams: Record<string, string>) => {
		const afWebUserId = document.cookie.replace(
			/(?:(?:^|.*;\s*)afUserId\s*=\s*([^;]*).*$)|^.*$/,
			'$1',
		);
		firstValueFrom(this.dataTrackingApi.track([{
			event_name: TrackingEventNames.webToAppOpen,
			af_web_user_id: afWebUserId || "",
			country_code: "",
			created_at: new Date().toISOString(),
			fngrprnt_id: "",
			gu_id: Container.take('global', 'guid'),
			id: uuidv4(),
			locale_name: "en",
			platform_id: "4",
			user_agent: window?.navigator?.userAgent,
			utm_params: JSON.stringify(queryParams),
			user_id: this.authService.getUser()?.id?.toString() || '0',
			version: '1-0-0'
		} as IBaseDataTracking]));
	};

	public trackFlowStart = (queryParams: Record<string, string>, flowId: number) => {
		this.trackingService.tracking$.next({
			eventName: TrackingEventNames.webToAppStart,
			eventParams: {
				version: '1-0-0',
				utmParams: JSON.stringify(queryParams),
				flowId: flowId.toString()
			}
		});
	};

	public trackPageImpression = (
		queryParams: Record<string, string>,
		flowId: number,
		pageId: number
	) => {
		this.trackingService.tracking$.next({
			eventName: TrackingEventNames.webToAppPageImpression,
			eventParams: {
				version: '1-0-0',
				utmParams: JSON.stringify(queryParams),
				flowId: flowId.toString(),
				pageId: pageId.toString()
			}
		});

	};

	public trackPageComplete = (
		options: {
			queryParams: Record<string, string>;
			flowId: number;
			pageId: number;
			answerTypeIds: number[];
			startPageId: number;
		}
	) => {
		const { queryParams, flowId, pageId, answerTypeIds, startPageId } = options;
		this.trackingService.tracking$.next({
			eventName: TrackingEventNames.webToAppPageComplete,
			eventParams: {
				version: '1-0-0',
				utmParams: JSON.stringify(queryParams),
				flowId: flowId.toString(),
				pageId: pageId.toString(),
				answerTypeIds: answerTypeIds.map(a => a.toString())
			}
		});
		if (startPageId === pageId) {
			if ((window as any).fbq !== undefined) {
				fbq('trackCustom', 'WTAStarted', {});
			}
		}
	};

	public trackQuizAnswer = (
		queryParams: Record<string, string>,
		flowId: number,
		pageId: number,
		quesitonId: number,
		action: "like" | "dislike"
	) => {
		this.trackingService.tracking$.next({
			eventName: TrackingEventNames.webToAppQuizAnswer,
			eventParams: {
				version: "1-0-0",
				utmParams: JSON.stringify(queryParams),
				flowId: flowId.toString(),
				pageId: pageId.toString(),
				questionId: quesitonId.toString(),
				action: action.toUpperCase()
			}
		});
	};

	public trackEmailProvided = () => {
		if ((window as any).fbq !== undefined) {
			fbq("trackCustom", "WTAEmailProvided", {});
		}
	};

	public trackEmailPageReached = () => {
		if ((window as any).fbq !== undefined) {
			fbq("trackCustom", "WTAEmailPageReached", {});
		}
	};
}