import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Container } from '../../../symphony';

export interface ISlMetadata {
	name: string;
	content: string;
	customProperties?: { [key: string]: string; };
}

const defaultTags = {
	title: 'Sololearn: Learn to Code',
	description: 'Sololearn is the world\'s largest community of people learning to code. With over 25 programming courses, choose from thousands of topics to learn how to code, brush up your programming knowledge, upskill your technical ability, or stay informed about the latest trends.',
	imgUrl: 'https://blob.sololearn.com/avatars/sololearn.png',
};

interface IMetaTag {
	name?:
	| 'application-name'
	| 'author'
	| 'description'
	| 'generator'
	| 'keywords'
	| 'viewport';
	'http-equiv'?:
	| 'content-security-policy'
	| 'content-type'
	| 'default-style'
	| 'refresh';
	content?: string;
	charset?: string;
}

export interface ISlHelmet {
	title?: string;
	description?: string;
	imgUrl?: string;
	additionalMetaTags?: IMetaTag[]; // if you want to add additional meta tags
	customCanonical?: string;
	structuredDataMarkup?: string;
	config?: {
		// For new Helmet using please ignore *config* and use *title*,*description*,*imgUrl*, *additionalMetaTags*
		title: string;
		metaData?: ISlMetadata[];
	};
}

export function SlHelmet({
	title = defaultTags.title,
	description = defaultTags.description,
	imgUrl = defaultTags.imgUrl,
	structuredDataMarkup,
	additionalMetaTags,
	customCanonical,
	config,
}: ISlHelmet): JSX.Element {
	const { pathname } = useLocation();
	const location = `${Container.take('global', 'host')}${pathname}`;

	// Todo: Temporary solution, until change in all MFs --------------
	if (config) {
		const { title: oldTitle, metaData } = config;
		return (
			<Helmet>
				{oldTitle ? <title>{oldTitle}</title> : null}
				{/* Canonical */}
				<link rel="canonical" href={customCanonical || location} />
				{metaData?.length && metaData.map((meta, index) => (
					<meta
						name={meta.name}
						content={meta.content}
						key={index}
						{...(meta?.customProperties
							? meta.customProperties
							: {})}
					/>
				))}
			</Helmet>
		);
	}
	// ----------------------------------------------------------------

	return (
		<Helmet>
			{/* Primary Meta Tags */}
			<title>{title}</title>
			<meta name="title" content={title} />
			<meta name="description" content={description} />

			{/* Open Graph / Facebook */}
			<meta property="og:type" content="website" />
			<meta property="og:url" content={location} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={imgUrl} />

			{/* Twitter */}
			<meta property="twitter:card" content="summary_large_image" />
			<meta property="twitter:url" content={location} />
			<meta property="twitter:title" content={title} />
			<meta property="twitter:description" content={description} />
			<meta property="twitter:image" content={imgUrl} />

			{/* Canonical */}
			<link rel="canonical" href={customCanonical || location} />

			{/* Structured Data Markup */}
			{structuredDataMarkup && (
				<script type="application/ld+json">
					{structuredDataMarkup}
				</script>
			)}

			{/* Additional Meta Tags */}
			{additionalMetaTags
				&& additionalMetaTags.map((t) => <meta {...t} />)}
		</Helmet>
	);
}
