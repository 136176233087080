import React, { FC, useEffect } from "react";
import { Container } from "../../../../../symphony";
import { WebToAppService } from "../../../services/webtoapp.service";
import { SlIcon } from "@sololearnorg/icons";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTAAIOrHumanPage.scss';
import { Padding } from "../../shared/Padding/Padding";
import { WTAPage } from "../../../types/webtoapp.types";
import { WTAComponentDrawer } from "../../pageComponents/WTAComponentDrawer/WTAComponentDrawer";

type Props = {
	page: WTAPage;
};

export const WTAAIOrHumanPage: FC<Props> = ({ page }) => {
	useStyles(s);
	const { footer, progressBar } = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.aiOrHuman();
		progressBar.hide();
	}, []);

	return <div className="wta-ai-game">
		<div className="wta-ai-game-inner">
			<div className="wta-ai-game-sololearn">
				<SlIcon iconId="sololearn" />
			</div>
			<WTAComponentDrawer
				components={page.content}
				pageOptions={page.pageOptions}
			/>
		</div>
	</div>;
};
