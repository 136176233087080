import { BehaviorSubject, catchError, combineLatest, concatMap, EMPTY, filter, firstValueFrom, Observable } from 'rxjs';
import { Action } from "history";
import { AuthService, Container, CourseTrack, history, Service, StorageService, UserTrackService } from "../../../symphony";
import { WebToAppStore } from "../stores/webtoapp.store";
import { WTAAIorHumanAnswers, WTAComponent, WTAComponentType, WTAPage, WTAPageOption, WTAPageType, WTAQuizQuestion } from "../types/webtoapp.types";
import { SlLoginContext } from "../../../login/private/login-context";
import { SlLoginApiService } from "../../../login/private/services/sl-login-api.service";
import { DynamicOnboardingApi, IOnboardingAnswerPayload } from '../../../api/public/dynamic-onboarding-api';
import { LearnEngineApi, LocationType } from '../../../api/public/learn-engine/learn-engine-api';
import { ProfileApi } from '../../../api/public/profile-api';
import { PaywallsService } from '../../../_paywalls/private/services/paywalls.service';
import { Touchpoint } from '../../../api/public/subscription-api';
import { WebToAppTrackingSerivce } from './webtoapp.tracking.service';
import { appleConfigs, appleScriptConfigs } from '../../../login/private/containers/SocialLogin/configs';
import { WTADefaultJourney, WTAInitializationPage } from '../wta.constants';

@Service()
export class WebToAppService {
	private store = Container.take('wta', WebToAppStore);
	private initialParams: Record<string, string> = null;
	private loginApi = Container.take(SlLoginContext, SlLoginApiService);
	private authService = Container.take('global', AuthService);
	private onboardingApi = Container.take('global', DynamicOnboardingApi);
	private learnEngineApi = Container.take('global', LearnEngineApi);
	private profileApi = Container.take('global', ProfileApi);
	private paywallService = Container.take('paywalls', PaywallsService);
	private userTrackService = Container.take('global', UserTrackService);
	private tracking = Container.take('wta', WebToAppTrackingSerivce);
	private storageService = Container.take('global', StorageService);
	private userSelection = new BehaviorSubject<IOnboardingAnswerPayload[]>(null);
	private navigationSelection: string = '';

	constructor() {
		this.subscribeToBrowserBack();
		this.subscribeToUserSelection();
	}

	private subscribeToBrowserBack = () => {
		history.listen(event => {
			if (event.action === Action.Pop) {
				this.navigation.back();
				this.definePage(true);
			}
		});
	};

	private subscribeToUserSelection = () => {
		this.userSelection.pipe(
			filter(payload => !!payload),
			concatMap(payload => this.onboardingApi.postUserAnswers(payload).pipe(
				catchError(err => EMPTY)
			))
		).subscribe();
	};

	private navigation = {
		init: () => {
			this.navigationSelection = this.storageService.load('wtaNavigation', 'local') || '';
		},
		set: () => {
			const { id: pageId, type } = this.store.page.value;
			let navigation = 0;
			if (type === WTAPageType.singleChoice) {
				navigation = this.store.singleChoiceAnswers.value[pageId].navigation;
			} else if (type === WTAPageType.topics) {
				navigation = this.store.selectedTopics.value[0].navigation;
			}
			this.navigationSelection = [
				this.navigationSelection,
				this.navigationSelection ? '|' : '',
				`${pageId}_${navigation}`
			].join('');
			this.storageService.save('wtaNavigation', this.navigationSelection, 'local');
		},
		back: () => {
			const parts = this.navigationSelection.split('|');
			parts.pop();
			this.navigationSelection = parts.join('|');
			this.storageService.save('wtaNavigation', this.navigationSelection, 'local');
		}
	};

	public initFlow = async (queryString: string) => {
		this.initialParams = Object.fromEntries(new URLSearchParams(queryString).entries());
		this.tracking.trackFlowOpen(this.initialParams);
		this.navigation.init();
		this.store.userEmail.next(this.storageService.load('wtaEmail', 'local') || null);
		this.store.page.next(WTAInitializationPage);
		!this.authService.isLoggedIn() && await this.createTempUser();
		this.userTrackService.setUserTrack(CourseTrack.AI);
		const journey = await this.getJourney();
		this.store.flow.next(journey.flow);
		this.store.journeyPages.next(journey.pages as WTAPage[]);
		this.prefetchAssets(journey.pages);
		this.tracking.trackFlowStart(this.initialParams, journey.flow.flowId);
		this.definePage(true);
	};

	private createTempUser = async () => {
		await firstValueFrom(this.loginApi.w2aSignup());
	};

	private getJourney = async () => {
		try {
			return (await firstValueFrom(this.onboardingApi.getWTAJourney(
				this.initialParams['utm_source'],
				this.initialParams['utm_medium'],
				this.initialParams['utm_campaign'],
				this.initialParams['utm_content'],
				this.initialParams['landingPageAlias']
			)));
		} catch (err) {
			return WTADefaultJourney;
		}
	};

	private definePage = (replaceHistory: boolean) => {
		const { value: pages } = this.store.journeyPages;
		const { startPageId, flowId, selections } = this.store.flow.value;
		const pageId = this.navigationSelection
			? selections.find(s => s.selection === this.navigationSelection).screenId
			: startPageId;
		const page = pages.find(p => p.id === pageId);
		this.tracking.trackPageImpression(this.initialParams, flowId, page.id);
		if (page.type === WTAPageType.emailUpdate) {
			this.tracking.trackEmailPageReached();
		}
		const historyParams = {
			pathname: window.location.pathname,
			search: new URLSearchParams({ ...this.initialParams, onbpage: page.id.toString() }).toString()
		};
		replaceHistory ? history.replace(historyParams) : history.push(historyParams);
		this.store.page.next(page);
		this.setProgressValue();
	};

	private setProgressValue = () => {
		const pagesLength = this.store.journeyPages.value.length;
		const pageIndex = this.store.journeyPages.value.indexOf(this.store.page.value) + 1;
		this.store.progressValue.next(pageIndex * 100 / pagesLength);
	};

	private prefetchAssets = (pages: WTAPage[]) => {
		const getComponentAssetUrls = (component: WTAComponent): string[] => {
			switch (component.type) {
				case WTAComponentType.image:
					return [component.data];
				case WTAComponentType.likeDislike:
					return component.questions.map(q => q.imageUrl);
				default:
					return [];
			}
		};
		const assetURLs: string[] = [];
		pages.forEach(page => {
			page.pageOptions?.forEach(option => {
				if (option.iconUrl) {
					assetURLs.push(option.iconUrl);
				}
			});
			page.content?.forEach(c => {
				assetURLs.push(...getComponentAssetUrls(c));
			});
		});
		assetURLs.forEach(url => {
			const img = new Image();
			img.src = url;
			img.style.display = 'none';
			img.onload = () => { img.remove(); };
			img.onerror = () => { img.remove(); };
			document.body.appendChild(img);
		});
	};

	public toNextPage = () => {
		if (this.store.page.value.type === WTAPageType.flowInitialization && !this.store.flow.value) {
			this.store.journeyLoading.next(true);
			const interval = setInterval(() => {
				if (this.store.flow.value) {
					this.toNextPage();
					this.store.journeyLoading.next(false);
					clearInterval(interval);
				}
			}, 100);
		} else {
			this.trackPageComplete();
			this.saveUserAnswers();
			this.navigation.set();
			this.definePage(false);
		}
	};

	private trackPageComplete = () => {
		const { flowId, startPageId } = this.store.flow.value;
		const { id: pageId, type } = this.store.page.value;

		let answerIds: number[] = [];
		if (type === WTAPageType.singleChoice) {
			answerIds.push(this.store.singleChoiceAnswers.value[pageId].answerTypeId);
		} else if (type === WTAPageType.topics) {
			answerIds.push(...this.store.selectedTopics.value.map(o => o.answerTypeId));
		} else if (type === WTAPageType.aiOrHuman) {
			answerIds.push(this.store.aiOrHumanAnswers.value[pageId]);
		}
		this.tracking.trackPageComplete({
			queryParams: this.initialParams,
			flowId,
			pageId,
			answerTypeIds: answerIds,
			startPageId
		});
	};

	private saveUserAnswers = () => {
		const { type, id: pageId } = this.store.page.value;
		if (type === WTAPageType.singleChoice) {
			this.userSelection.next([{
				typeId: this.store.singleChoiceAnswers.value[pageId].questionTypeId,
				answerId: this.store.singleChoiceAnswers.value[pageId].answerTypeId
			}]);
		} else if (type === WTAPageType.topics) {
			this.userSelection.next(this.store.selectedTopics.value.map(st => ({
				typeId: st.questionTypeId,
				answerId: st.answerTypeId
			})));
		}
		return;
	};

	private setFooterState = (state: 'disabled' | 'loading' | 'enabled' | 'hidden' | 'getStarted' | 'aiorhuman') => {
		this.store.footer.next(state);
	};

	public footer = {
		hide: () => this.setFooterState('hidden'),
		disable: () => this.setFooterState('disabled'),
		enable: () => this.setFooterState('enabled'),
		load: () => this.setFooterState('loading'),
		getStarted: () => this.setFooterState('getStarted'),
		aiOrHuman: () => this.setFooterState('aiorhuman')
	};

	private setProgressBarState = (state: 'hidden' | 'shown') => {
		this.store.progressBar.next(state);
	};

	public progressBar = {
		hide: () => this.setProgressBarState('hidden'),
		show: () => this.setProgressBarState('shown')
	};

	public defineQuizQuestions = (questions: WTAQuizQuestion[]): WTAQuizQuestion[] => {
		const selectedTopics = this.store.selectedTopics.value;
		if (!selectedTopics) {
			const shuffled = questions.sort(() => 0.5 - Math.random());
			return shuffled.splice(0, 5);
		}
		let filteredQuestions = questions.filter(question =>
			selectedTopics.some(topic => question.topicIds.includes(topic.answerTypeId))
		);
		const selectedQuestions: any[] = [];
		const topicsCovered = new Set<number>();
		selectedTopics.forEach(topic => {
			const topicQuestions = filteredQuestions.filter(question => question.topicIds.includes(topic.answerTypeId));
			if (topicQuestions.length > 0) {
				const selectedQuestion = topicQuestions[0];
				if (!topicsCovered.has(topic.answerTypeId)) {
					selectedQuestions.push(selectedQuestion);
					topicsCovered.add(topic.answerTypeId);
					filteredQuestions = filteredQuestions.filter(question => question.id !== selectedQuestion.id);
				}
			}
		});
		const remainingSlots = 5 - selectedQuestions.length;
		const randomRemainingQuestions = filteredQuestions.sort(() => 0.5 - Math.random()).slice(0, remainingSlots);
		return selectedQuestions.concat(randomRemainingQuestions).sort(() => 0.5 - Math.random());
	};

	private enrollToCourses = (aliases: string[]) => {
		const combined: Observable<any>[] = [];
		aliases.forEach(alias => {
			combined.push(this.learnEngineApi.enrollCourse({
				alias, locationTypeId: LocationType.Onboarding
			}).pipe(catchError(e => e)));
		});
		const subscription = combineLatest(combined).subscribe(
			() => subscription.unsubscribe()
		);
	};

	public setExperimentData = (key: string, payload: Record<string, any>) => {
		this.store.experimentsData.next({
			...this.store.experimentsData.value,
			[key]: payload
		});
	};

	// PAGE HANDLERS
	public topicPageHandler = (selectedTopcis: WTAPageOption[]) => {
		this.store.selectedTopics.next(selectedTopcis);
		if (selectedTopcis.length) {
			this.footer.enable();
		} else {
			this.footer.disable();
		}
	};

	public singleChoicePageHandler = (option: WTAPageOption) => {
		this.footer.enable();
		const singleChoiceAnswers = this.store.singleChoiceAnswers.value;
		this.store.singleChoiceAnswers.next({
			...singleChoiceAnswers,
			[this.store.page.value.id]: option
		});
	};

	public loadingPageHandler = () => {
		setTimeout(() => {
			this.toNextPage();
		}, 4000);
	};

	public quizPageHandler = (question: WTAQuizQuestion, result: 'like' | 'dislike', isCompleted: boolean) => {
		this.tracking.trackQuizAnswer(
			this.initialParams,
			this.store.flow.value.flowId,
			this.store.page.value.id,
			question.id,
			result
		);
		if (result === 'like') {
			this.enrollToCourses(question.aliases);
		}
		if (isCompleted) {
			this.toNextPage();
		}
	};

	public emailUpdatePageHandler = async (email: string) => {
		this.store.emailUpdateError.next(null);
		const user = this.authService.getUser();
		try {
			await firstValueFrom(this.profileApi.updateInfo(
				this.authService.getUser().id,
				{
					email,
					name: user.name,
					countryCode: user.countryCode,
					bio: ''
				}
			));
			this.storageService.save('wtaEmail', email, 'local');
			this.tracking.trackEmailProvided();
			this.store.userEmail.next(email);
			this.toNextPage();
		} catch (err) {
			this.store.emailUpdateError.next(err);
		}
	};

	public completeRegistrationPageHandler = async (
		payload: {
			password?: string;
		}
	) => {
		const { password } = payload;
		await firstValueFrom(this.loginApi.setPassword(null, password));
		this.toNextPage();
	};

	public paywallPageHandler = () => {
		this.paywallService.openPaywall({
			touchpoint: Touchpoint.web2app,
			props: {}
		}, {
			onClose: (method: 'back' | 'x') => {
				if (method !== 'back') {
					this.toNextPage();
				}
			}
		});
	};

	public googleLoginHandler = async (res: any) => {
		this.store.socialAccountError.next(null);
		const { access_token: accessToken } = res.getAuthResponse();
		try {
			await firstValueFrom(this.loginApi.socialLogin({ accessToken, provider: 'google' }));
			this.toNextPage();
		} catch (ex) {
			this.store.socialAccountError.next(ex);
		}
	};

	public appleLoginHandler = async () => {
		this.store.socialAccountError.next(null);
		try {
			const tokens = await window.AppleID?.auth?.signIn();
			await firstValueFrom(this.loginApi.authorizeApple({
				provider: 'Apple',
				code: tokens.authorization.code,
				user: {
					firstName: tokens.user?.name?.firstName,
					lastName: tokens.user?.name?.lastName,
				}
			}));
			this.toNextPage();
		} catch (ex) {
			this.store.socialAccountError.next(ex);
		}
	};

	public appleLoginInit = () => {
		const script = document.createElement('script');
		script.src = appleScriptConfigs.src;
		script.type = appleScriptConfigs.type;
		script.onload = () => {
			window.AppleID?.auth?.init({ ...appleConfigs, redirectURI: Container.take('global', 'redirectURI') });
		};
		document.body.appendChild(script);
	};

	public aiOrHumanPageHandler = (answer: WTAAIorHumanAnswers) => {
		const { id: pageId } = this.store.page.value;
		this.store.aiOrHumanAnswers.next({
			...this.store.aiOrHumanAnswers.value,
			[pageId]: answer
		});
		this.toNextPage();
	};
}
