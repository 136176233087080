import { BehaviorSubject } from "rxjs";
import { Service } from "../../../symphony";
import { WTAAIorHumanAnswers, WTAFlow, WTAPage, WTAPageOption } from "../types/webtoapp.types";

@Service()
export class WebToAppStore {
	public progressValue = new BehaviorSubject<number>(0);
	public progressBar = new BehaviorSubject<'shown' | 'hidden'>('hidden');
	public page = new BehaviorSubject<WTAPage>(null);
	public journeyPages = new BehaviorSubject<WTAPage[]>([]);
	public flow = new BehaviorSubject<WTAFlow>(null);
	public footer = new BehaviorSubject<'disabled' | 'loading' | 'enabled' | 'hidden' | 'getStarted' | 'aiorhuman'>('hidden');
	public journeyLoading = new BehaviorSubject<boolean>(false);
	public experimentsData = new BehaviorSubject<{ [key: string]: Record<string, any>; }>({});

	//USER SELECTIONS
	public selectedTopics = new BehaviorSubject<WTAPageOption[]>(null);
	public singleChoiceAnswers = new BehaviorSubject<{ [pageId: number]: WTAPageOption; }>({});
	public aiOrHumanAnswers = new BehaviorSubject<{ [pageId: number]: WTAAIorHumanAnswers; }>({});
	public emailUpdateError = new BehaviorSubject<Error>(null);
	public socialAccountError = new BehaviorSubject<Error>(null);
	public userEmail = new BehaviorSubject<string>(null);
}
