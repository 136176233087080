import React, { ChangeEvent, FC, useEffect, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTAEmailUpdatePage.scss';
import { Container, i18n, Trans } from "../../../../../symphony";
import { WebToAppService } from "../../../services/webtoapp.service";
import { Padding } from "../../shared/Padding/Padding";
import { WTAButton } from "../../shared/WTAButton/WTAButton";
import { SlIcon } from "@sololearnorg/icons";
import { LocalizedLink } from "../../../../../shared/public/LocalizedLink/LocalizedLink";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { WebToAppStore } from "../../../stores/webtoapp.store";
import { Heading } from "../../pageComponents/Heading/Heading";
import { exposeExperiment } from "../../../../../shared/public/experimentsHandler/experimentsHandler";

type Props = {};

export const WTAEmailUpdatePage: FC<Props> = () => {
	useStyles(s);
	const [email, setEmail] = useState<{ email: string, isValid: boolean; }>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(true);
	const [version, setVersion] = useState<number>(null);
	const {
		emailUpdateError,
		userEmail,
		experimentsData
	} = useContainerData('wta', WebToAppStore, ['emailUpdateError', 'userEmail', 'experimentsData']);

	const {
		footer,
		progressBar,
		emailUpdatePageHandler,
		toNextPage
	} = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.hide();
		progressBar.hide();
	}, []);

	useEffect(() => {
		if (emailUpdateError) {
			setLoading(false);
		}
	}, [emailUpdateError]);

	useEffect(() => {
		setIsEditing(!userEmail);
	}, [userEmail]);

	useEffect(() => {
		if (experimentsData && experimentsData['wta-email-page']) {
			const expVersion = experimentsData['wta-email-page']['version'];
			setVersion(expVersion);
			exposeExperiment('wta-email-page');
		}
	}, [experimentsData]);

	const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail({
			email: event.target.value,
			isValid: /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+)@(?:(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/.test(event.target.value)
		});
	};

	const onEditClick = () => {
		setIsEditing(true);
	};

	const onSubmit = () => {
		if (isEditing) {
			setLoading(true);
			emailUpdatePageHandler(email.email);
		} else {
			toNextPage();
		}
	};

	const version1 = () => <Padding className="wta-email-update">
		<div className="wta-email-update-logo">
			<SlIcon iconId="sololearn" />
		</div>
		<Heading
			locKey="wta.update-email.title"
			level={2}
			style={{
				paddingBottom: 0
			}}
		/>
		<div className="wta-email-update-form">
			<label
				htmlFor="wta-email-update"
				className="wta-email-update-label"
			>{i18n.t('wta.update-email.label')}</label>
			{isEditing ? <>
				<input
					type="email"
					name="wta-email-update"
					id="wta-email-update"
					placeholder={i18n.t('wta.update-email.input-placeholder')}
					className={`wta-email-update-input ${emailUpdateError ? 'error' : ''}`}
					disabled={loading}
					onChange={onEmailChange}
					defaultValue={userEmail || ''}
				/>
			</> : <div className="wta-email-update-existing-email">
				<span>{userEmail}</span>
				<div className="wta-email-update-edit" onClick={onEditClick}>
					<SlIcon iconId={'pencil'} />
				</div>
			</div>}
			{emailUpdateError && <caption className="wta-email-update-error">{i18n.t('wta.update-email.error.existing')}</caption>}
			<p className="wta-email-update-disclaimer">
				<Trans
					i18nKey="wta.update-email.caution"
					components={[
						<LocalizedLink
							to={'/privacy-policy'}
							relative="route"
							target="_blank"
						/>,
						<LocalizedLink
							to={'/Terms-of-Use'}
							relative="route"
							target="_blank"
						/>,
					]}
				/>
			</p>
		</div>

		<WTAButton
			onClick={onSubmit}
			background="purple"
			locKey="wta.update-email.cta"
			disabled={isEditing && !email?.isValid}
			loading={loading}
		/>

		<div className="wta-email-update-awards">
			<div className="wta-email-update-award">
				<SlIcon iconId="appleGenerativeAward" />
			</div>
			<div className="wta-email-update-award">
				<SlIcon iconId="googlePlayEditorsChoice" />
			</div>
		</div>
	</Padding>;

	const version2 = () => <Padding className="wta-email-update" style={{ flex: 1 }}>
		{loading && <div className="wta-email-update-loader" style={{
			position: 'fixed',
			width: '100%',
			height: '100%',
			top: 0,
			left: 0,
			backgroundColor: 'rgb(255, 255, 255, 0.8)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}}>
			<div className="wta-email-update-ellipsis-loader">
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>}
		<div className="wta-email-update-logo">
			<SlIcon iconId="sololearn" />
		</div>
		<Heading
			locKey="Provide your email to access your personalized learning"
			level={2}
			style={{
				paddingBottom: 0
			}}
		/>
		<div className="wta-email-update-form">
			{isEditing ? <>
				<input
					type="email"
					name="wta-email-update"
					id="wta-email-update"
					placeholder={i18n.t('wta.update-email.input-placeholder')}
					className={`wta-email-update-input ${emailUpdateError ? 'error' : ''}`}
					disabled={loading}
					onChange={onEmailChange}
					defaultValue={userEmail || ''}
				/>
			</> : <div className="wta-email-update-existing-email">
				<span>{userEmail}</span>
				<div className="wta-email-update-edit" onClick={onEditClick}>
					<SlIcon iconId={'pencil'} />
				</div>
			</div>}
			{emailUpdateError && <caption className="wta-email-update-error">{i18n.t('wta.update-email.error.existing')}</caption>}

		</div>

		<div style={{ marginTop: '-20px' }}>
			<WTAButton
				onClick={onSubmit}
				background="purple"
				locKey="wta.update-email.cta"
				disabled={isEditing && !email?.isValid}
			/>
		</div>

		<div className="wta-email-update-awards" style={{ height: '88px', marginTop: '-20px' }}>
			<div className="wta-email-update-award">
				<SlIcon iconId="appleGenerativeAward" />
			</div>
			<div className="wta-email-update-award">
				<SlIcon iconId="googlePlayEditorsChoice" />
			</div>
		</div>
		<div style={{ display: 'flex', flex: 1, alignItems: 'flex-end', paddingBottom: '20px' }}>
			<p className="wta-email-update-disclaimer" style={{ textAlign: 'center', fontSize: '12px', color: '#383838' }}>
				<Trans
					i18nKey="wta.update-email.caution"
					components={[
						<LocalizedLink
							to={'/privacy-policy'}
							relative="route"
							target="_blank"
						/>,
						<LocalizedLink
							to={'/Terms-of-Use'}
							relative="route"
							target="_blank"
						/>,
					]}
				/>
			</p>
		</div>
	</Padding>;

	return version === 1 ? version1() : version2();
};