import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { WTAGenericPage } from "../WTAGenericPage/WTAGenericPage";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTAPageDrawer.scss';
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { WebToAppStore } from "../../../stores/webtoapp.store";
import { Container } from "../../../../../symphony";
import { WebToAppService } from "../../../services/webtoapp.service";
import { Padding } from "../../shared/Padding/Padding";
import { ProgressBar } from "../../shared/ProgressBar/ProgressBar";
import { WTAAIorHumanAnswers, WTAPageType } from "../../../types/webtoapp.types";
import { WTASingleChoicePage } from "../WTASingleChoicePage/WTASingleChoicePage";
import { motion, AnimatePresence } from 'framer-motion';
import { WTAQuizPage } from "../WTAQuizPage/WTAQuizPage";
import { WTATopicsPage } from "../WTATopicsPage/WTATopicsPage";
import { WTALoadingPage } from "../WTALoadingPage/WTALoadingPage";
import { WTAGetStartedPage } from "../WTAGetStartedPage/WTAGetStartedPage";
import { WTAButton } from "../../shared/WTAButton/WTAButton";
import { WTAEmailUpdatePage } from "../WTAEmailUpdatePage/WTAEmailUpdatePage";
import { WTACompleteAccountPage } from "../WTACompleteAccountPage/WTACompleteAccountPage";
import { WTAPaywallPage } from "../WTAPaywallPage/WTAPaywallPage";
import { WTADownloadAppPage } from "../WTADownloadAppPage/WTADownloadAppPage";
import { WTAAIOrHumanPage } from "../WTAAIOrHumanPage/WTAAIOrHumanPage";
import { WTAFlowInitializationPage } from "../WTAFlowInitializationPage/WTAFlowInitializationPage";
import { useExperimentData } from "../../../../../shared/public/experimentsHandler/experimentsHandler";

const animationConfig = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		transition: {
			duration: 1,
		},
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 1,
		},
	},
};

export const WTAPageDrawer: FC = () => {
	useStyles(s);
	const {
		page,
		footer,
		progressBar,
		journeyLoading
	} = useContainerData('wta', WebToAppStore, ['page', 'footer', 'progressBar', 'journeyLoading']);
	const { toNextPage, aiOrHumanPageHandler, setExperimentData } = Container.take('wta', WebToAppService);
	const divRef = useRef(null);
	const [hasVerticalScroll, setHasVerticalScroll] = useState(false);
	const wtaEmailPageExperiment = useExperimentData('wta-email-page', false);

	const checkVerticalScroll = useCallback(() => {
		if (divRef.current) {
			const { scrollHeight, clientHeight } = divRef.current;
			setHasVerticalScroll(scrollHeight > clientHeight);
		}
	}, [divRef]);

	useEffect(() => {
		checkVerticalScroll();
		setTimeout(checkVerticalScroll, 1000);
		window.addEventListener('resize', checkVerticalScroll);
		return () => {
			window.removeEventListener('resize', checkVerticalScroll);
		};
	}, [checkVerticalScroll, page]);

	useEffect(() => {
		wtaEmailPageExperiment && setExperimentData('wta-email-page', wtaEmailPageExperiment.payload);
	}, [wtaEmailPageExperiment]);

	return <div className={`wta-page ${page?.meta?.style?.background || ''}`}>
		<div className="wta-page-content" ref={divRef}>
			<div className="wta-page-inner-container">
				{progressBar !== 'hidden' && <Padding><ProgressBar /></Padding>}
				<AnimatePresence mode="wait">
					<div className="wta-page-components">
						<motion.div
							key={page?.type}
							initial="initial"
							animate="animate"
							exit="exit"
							variants={animationConfig}
							style={{
								flex: '1',
								display: 'flex',
								flexDirection: 'column',
								maxWidth: '100%'
							}}
						>
							{page?.type === WTAPageType.getStarted && <WTAGetStartedPage key={page.id} page={page} />}
							{page?.type === WTAPageType.generic && <WTAGenericPage key={page.id} page={page} />}
							{page?.type === WTAPageType.singleChoice && <WTASingleChoicePage key={page.id} page={page} />}
							{page?.type === WTAPageType.quiz && <WTAQuizPage key={page.id} page={page} />}
							{page?.type === WTAPageType.topics && <WTATopicsPage key={page.id} page={page} />}
							{page?.type === WTAPageType.loading && <WTALoadingPage key={page.id} page={page} />}
							{page?.type === WTAPageType.emailUpdate && <WTAEmailUpdatePage key={page.id} />}
							{page?.type === WTAPageType.completeAccountRegistration && <WTACompleteAccountPage key={page.id} />}
							{page?.type === WTAPageType.paywall && <WTAPaywallPage key={page.id} />}
							{page?.type === WTAPageType.downloadApp && <WTADownloadAppPage key={page.id} />}
							{page?.type === WTAPageType.flowInitialization && <WTAFlowInitializationPage key={page.id} />}
							{page?.type === WTAPageType.aiOrHuman && <WTAAIOrHumanPage key={page.id} page={page} />}
						</motion.div>
					</div>
				</AnimatePresence>
			</div>
		</div>
		{footer !== 'hidden' && <div className={`wta-page-footer ${hasVerticalScroll ? 'with-shadow' : ''}`}>
			<div className="wta-page-footer-inner">
				<Padding>
					{footer !== 'aiorhuman' && <WTAButton
						onClick={toNextPage}
						disabled={footer === 'disabled'}
						locKey={page?.cta || footer === 'getStarted' ? "wta.cta.start" : "wta.cta.continue"}
						background={page?.meta?.style?.background === 'purple' ? 'white' : 'purple'}
						loading={journeyLoading}
					/>}
					{footer === 'aiorhuman' && <div className="wta-page-footer-two-buttons">
						<WTAButton
							className="wta-page-footer-button"
							onClick={() => aiOrHumanPageHandler(WTAAIorHumanAnswers.ai)}
						>
							<span>AI</span> <span className="wta-page-footer-button-emoji">🤖</span>
						</WTAButton>
						<WTAButton
							className="wta-page-footer-button"
							onClick={() => aiOrHumanPageHandler(WTAAIorHumanAnswers.human)}
						>
							<span>HUMAN</span> <span className="wta-page-footer-button-emoji">🙎‍♂️</span>
						</WTAButton>
					</div>}
				</Padding>
			</div>
		</div>}
	</div>;
};
