import { Observable } from 'rxjs';
import { PaywallTouchPoint } from '../payment-api/payment-api.interface';
import { WTAJourney } from '../../../_webtoapp/private/types/webtoapp.types';

export enum OnboardingPageType {
	Motivation = 'motivation',
	Generic = 'generic',
	CourseRecommendation = 'recommendationV2',
	FakePath = 'fakePath',
	FakePathCheckBox = 'fakePathCheckBox',
	LearningMaterials = 'learningMaterialsV2',
	CourseSelection = 'courseSelection',
	CourseDetails = 'courseDetails',
	Paywall = 'paywall',
}

export enum OnboardingMotivationAnimationName {
	Onboarding1 = 'Onboarding_1',
	Onboarding2 = 'Onboarding_2',
	Onboarding3 = 'Onboarding_3',
	Onboarding3V1 = 'Onboarding_3V1',
	Onboarding3V2 = 'Onboarding_3V2',
	Onboarding4 = 'Onboarding_4',
	Onboarding4V1 = 'Onboarding_4V1',
	Onboarding4V2 = 'Onboarding_4V2',
	Onboarding5 = 'Onboarding_5',
}

export interface IDynamicOnboardingApi {
	getDynamicOnboardingFlow: (alias?: string) => Observable<IOnboardingData>;
	postUserAnswers: (payload: IOnboardingAnswerPayload[]) => Observable<null>;
	getLearningMaterialsDetails: () => Observable<ILearningMaterialsDetails>;
	getWTAJourney: (
		utm_source: string,
		utm_medium: string,
		utm_campaign: string,
		utm_content: string,
		landingPageAlias?: string
	) => Observable<WTAJourney>;
}

export interface IOnboardingData {
	pages: IOnboardingPage[];
	flow: IOnboardingFlow;
	options?: IOnboardingOptions;
}

export interface IOnboardingPage {
	id: number;
	type: OnboardingPageType;
	pageName: string;
	title: string;
	showBackButton: boolean;
	cta: string;
	content:
	| IOnboardingMotivationPageContent[]
	| IOnboardingGenericPageContent[]
	| IOnboardingCourseDetailsPageContent[]
	| IOnboardingLearningMaterialsPageContent[]
	| IOnboardingFakePathPageContent[]
	| IOnboardingFakePathCheckBoxPageContent[]
	| IOnboardingCourseSelectionPageContent[];
	pageOptions: IOnboardingPageOption[];
	meta: IOnboardingMetaData;
	style: unknown;
}

export interface IOnboardingMotivationPageContent {
	text: string;
	button: string;
	animation: OnboardingMotivationAnimationName;
}

export interface IOnboardingGenericPageContent {
	contentType: string;
	value: string;
	shouldRandomize: boolean;
	options: number[];
}

export interface IOnboardingCourseDetailsPageContent {
	screenBottomImage: string;
	secondaryButton?: string;
}

export interface IOnboardingLearningMaterialsPageContent {
	sections: {
		title: string;
		key: string;
	}[];
}

export interface IOnboardingFakePathPageContent {
	headline: string;
	headlineFontColor?: string;
	title: string;
	caption: string;
	coursesHeadline: string;
	footer: string;
	ctaColor?: string;
	secondaryButton?: string;
	courseAlias: string;
	courses: ICourseBriefDetails[];
}

export interface IOnboardingFakePathCheckBoxPageContent {
	headline: string;
	title: string;
	footer: string;
	body: string; // html
	checkBoxDefaultValue: boolean;
	checkBoxText: string;
	courseAliasChecked: string;
	courseAliasUnchecked: string;
	imageUrl: string;
	secondaryButton: string;
}

export interface IOnboardingCourseSelectionPageContent {
	title: string;
	subtitle: string;
	imageUrl: string;
	body: string;
	ctas: ICourseSelectionCta[];
}

interface ICourseBriefDetails {
	text: string;
	highlightedText: string;
	imageUrl: string;
	textParts?: string[]; // For local usage
}

interface ICourseSelectionCta {
	text: string;
	courseAlias: string;
}

export interface IOnboardingPageOption {
	id: number;
	pageId: number;
	label: string;
	preselected: boolean;
	value: number;
	navigation: number;
	legend: string;
	answerTypeId: number;
	answerId: number;
	iconUrl: string;
}

export interface IOnboardingMetaData {
	navigation?: {
		courseNavigation: number;
		showAllNavigation: number;
	};
	style?: unknown;
	pages?: IOnboardingSubPageInfo[];
	touchPoint?: PaywallTouchPoint;
}

export interface IOnboardingSubPageInfo {
	index: number;
	name: string;
}

export interface IOnboardingFlow {
	startPageId: number;
	selections: IDynamicOnboardingFlowSelection[];
	flowId: number;
}

export interface IDynamicOnboardingFlowSelection {
	selection: string;
	screenId?: number;
}

export interface IOnboardingOptions {
	landingPageAlias?: string;
	selectedCourseAlias?: string;
}

export interface IOnboardingAnswerPayload {
	typeId: number;
	answerId?: number;
	courseAlias?: string;
}

export interface ILearningMaterialsDetails {
	allCourses: ICourseDetails[];
	sections: {
		[key: string]: string[];
	};
}

export interface ICourseDetails {
	alias: string;
	logoUrl: string;
	title: string;
	description: string;
	highlights: ICourseHighlightDetails[];
}

interface ICourseHighlightDetails {
	showPoint: boolean;
	text: string;
}
