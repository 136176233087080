import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Padding.scss';

type Props = {
	children: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
};

export const Padding: FC<Props> = ({ children, className, style }) => {
	useStyles(s);
	return <div style={style} className={`padding ${className || ''}`}>{children}</div>;
};