import { Observable } from 'rxjs';
import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { constructUrl } from '../private/utils/httpParamsUtils';
import {
	IApiUrls,
	IExperimentsDataResponse,
	IResponse,
} from '../private/global.interface';

import {
	IExperimentApi,
	IExperimentApiConfig,
	ExperimentNames,
	IResolveExperimentResponse,
	IExposeExperimentResponse,
} from '../private/experiment-api/experiment-api.interface';
import { IPaymentExperimentResponse } from '../private/experiment-api/pro-banner.interface';
import { IWelcomeBackExperimentResponse } from '../private/experiment-api/welcome-back.interface';
import { IProOnboardingExperimentResponse } from '../private/experiment-api/pro-onboarding.interface';
import { IStartPromptExperimentResponse } from '../private/experiment-api/start-prompt.interface';
import { IQuitPromptExperimentResponse } from '../private/experiment-api/quit-prompt.interface';
import { ILessonLandingExperimentResponse } from '../private/experiment-api/lessons-landing.interface';
import { IHighlightCCExperimentResponse } from '../private/experiment-api/highlight-cc.interface';
import { ISurveyCoursesExperimentResponse } from '../private/experiment-api/survey-courses.interface';
import { IFreeCodeRepoExperimentResponse } from '../private/experiment-api/free-code-repos.interface';
import { ICRProgressHintExperimentResponse } from '../private/experiment-api/cr-progress-hint.interface';
import { IDailyDoseExperimentResponse } from '../private/experiment-api/daily-dose.interface';
import { ICodeCoachCommentsExperimentResponse } from '../private/experiment-api/cc-comments.interface';
import { IUserGuidanceExperimentResponse } from '../private/experiment-api/user-guidance.interface';
import { ICelebrationScreenExperimentResponse } from '../private/experiment-api/celebration-screen.interface';
import { ICCMandatoryExperimentResponse } from '../private/experiment-api/cc-mandatory.interface';
import { ICCSolutionExperimentResponse } from '../private/experiment-api/cc-solution.interface';
import { IExperimentalCoursesExperimentResponse } from '../private/experiment-api/experimental-courses.interface';
import { IDynamicProPageExperimentResponse } from '../private/experiment-api/dynamic-pro.interface';
import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from '../private/api-context';
import { Service, Container, AuthService } from '../../symphony';

@Service()
export class ExperimentApi implements IExperimentApi {
	private environmentUrl: string;

	private newExperimentUrl: string;

	private fingerprint: string;

	private userId: number;

	private env: string;

	private axiosInstance: AxiosInstanceType;

	constructor(config = {} as IExperimentApiConfig) {
		this.environmentUrl = config?.environmentUrl
			|| (Container.take('global', 'envUrl') as IApiUrls)
				?.experimentsApiHost;

		this.newExperimentUrl = (
			Container.take('global', 'envUrl') as IApiUrls
		)?.newExperimentApiHost;

		this.fingerprint = Container.take('global', 'fingerprint');
		this.userId = Container.take('global', AuthService).getUser()?.id;
		this.env = Container.take('global', 'env');

		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

	private getExperimentData<T extends IResponse>(
		experiment: ExperimentNames,
		userId?: number,
	): Observable<T> {
		return this.axiosInstance.get(`${this.environmentUrl}/api/flows`, {
			params: {
				experiment,
				fingerprint: this.fingerprint,
				language: 'en',
				platform: 1,
				userId: userId || this.userId,
				testerKey:
					this.env !== 'prod'
						? 'quCK0dKu5D5A1niUb342r3StWpesi3D4'
						: '',
			},
		});
	}

	@Authorize(AuthLevel.public)
	public getDynamicProPageExperimentData(): Observable<IDynamicProPageExperimentResponse> {
		return this.getExperimentData(ExperimentNames.dynamicProPage);
	}

	@Authorize(AuthLevel.public)
	public getPaymentExperimentData(): Observable<IPaymentExperimentResponse> {
		return this.getExperimentData(ExperimentNames.payments);
	}

	@Authorize(AuthLevel.authenticated)
	public getWelcomeBackExperimentData(
		userId: number,
	): Observable<IWelcomeBackExperimentResponse> {
		return this.getExperimentData(ExperimentNames.welcomeBack, userId);
	}

	@Authorize(AuthLevel.authenticated)
	public getProOnboardingExperimentData(): Observable<IProOnboardingExperimentResponse> {
		return this.getExperimentData(ExperimentNames.proOnboarding);
	}

	@Authorize(AuthLevel.authenticated)
	public getCCSolutionExperimentData(
		userId: number,
	): Observable<ICCSolutionExperimentResponse> {
		return this.getExperimentData(ExperimentNames.ccSolution, userId);
	}

	@Authorize(AuthLevel.authenticated)
	public getStartPromptExperimentData(): Observable<IStartPromptExperimentResponse> {
		return this.getExperimentData(ExperimentNames.startPrompt);
	}

	@Authorize(AuthLevel.authenticated)
	public getQuitPromptExperimentData(): Observable<IQuitPromptExperimentResponse> {
		return this.getExperimentData(ExperimentNames.quitPrompt);
	}

	public getHighlightCCExperimentData(): Observable<IHighlightCCExperimentResponse> {
		return this.getExperimentData(ExperimentNames.highlightCC);
	}

	@Authorize(AuthLevel.authenticated)
	public getLessonLandingExperimentData(): Observable<ILessonLandingExperimentResponse> {
		return this.getExperimentData(ExperimentNames.courseLanding);
	}

	@Authorize(AuthLevel.authenticated)
	public getDailyDoseExperimentData(
		userId: number,
	): Observable<IDailyDoseExperimentResponse> {
		return this.getExperimentData(ExperimentNames.dailyDose, userId);
	}

	@Authorize(AuthLevel.authenticated)
	public getSurveyCoursesExperimentData(
		userId: number,
	): Observable<ISurveyCoursesExperimentResponse> {
		return this.getExperimentData(ExperimentNames.surveyCourses, userId);
	}

	@Authorize(AuthLevel.authenticated)
	public getFreeCRExperimentData(): Observable<IFreeCodeRepoExperimentResponse> {
		return this.getExperimentData(ExperimentNames.freeCRCount);
	}

	@Authorize(AuthLevel.authenticated)
	public getCRProgressHintExperimentData(): Observable<ICRProgressHintExperimentResponse> {
		return this.getExperimentData(ExperimentNames.crProgressHint);
	}

	@Authorize(AuthLevel.authenticated)
	public getCCCommentsExperimentData(
		userId?: number,
	): Observable<ICodeCoachCommentsExperimentResponse> {
		return this.getExperimentData(ExperimentNames.ccComments, userId);
	}

	@Authorize(AuthLevel.authenticated)
	public getUserGuidanceExperimentData(
		userId: number,
	): Observable<IUserGuidanceExperimentResponse> {
		return this.getExperimentData(ExperimentNames.userGuidance, userId);
	}

	@Authorize(AuthLevel.authenticated)
	public getCelebrationScreenExperimentData(): Observable<ICelebrationScreenExperimentResponse> {
		return this.getExperimentData(ExperimentNames.celebrationScreen);
	}

	@Authorize(AuthLevel.authenticated)
	public getCCMandatoryExperimentData(
		userId?: number,
	): Observable<ICCMandatoryExperimentResponse> {
		return this.getExperimentData(ExperimentNames.ccMandatory, userId);
	}

	@Authorize(AuthLevel.authenticated)
	public getExperimentalCoursesData(
		userId?: number,
	): Observable<IExperimentalCoursesExperimentResponse> {
		return this.getExperimentData(
			ExperimentNames.experimentalCourses,
			userId,
		);
	}

	@Authorize(AuthLevel.authenticated)
	public getExperimentsData(
		userId: number,
		experiments: ExperimentNames[],
	): Observable<IExperimentsDataResponse> {
		return this.axiosInstance.get(
			constructUrl(this.environmentUrl, 'api/flows/for-experiments'),
			{
				params: {
					experiments: experiments.join(),
					fingerprint: this.fingerprint,
					language: 'en',
					platform: 1,
					userId,
					testerKey:
						this.env !== 'prod'
							? 'quCK0dKu5D5A1niUb342r3StWpesi3D4'
							: '',
				},
			},
		);
	}

	@Authorize(AuthLevel.public)
	public exposeExperiment(
		experimentKey: string,
	): Observable<IExposeExperimentResponse> {
		return this.axiosInstance.post(
			constructUrl(this.newExperimentUrl, 'api/expose'),
			{
				experimentKey,
			},
		);
	}

	@Authorize(AuthLevel.public)
	public resolveExperiment(
		experimentKey: string,
	): Observable<IResolveExperimentResponse> {
		return this.axiosInstance.get(
			constructUrl(
				this.newExperimentUrl,
				`api/resolve/single/${experimentKey}`,
			),
		);
	}
}

export * from '../private/experiment-api/dynamic-pro.interface';
export * from '../private/experiment-api/experiment-api.interface';
export * from '../private/experiment-api/cc-solution.interface';
export * from '../private/experiment-api/pro-banners.interface';
export * from '../private/experiment-api/pro-banner.interface';
export * from '../private/experiment-api/pro-onboarding.interface';
export * from '../private/experiment-api/start-prompt.interface';
export * from '../private/experiment-api/welcome-back.interface';
export * from '../private/experiment-api/quit-prompt.interface';
export * from '../private/experiment-api/lessons-landing.interface';
export * from '../private/experiment-api/highlight-cc.interface';
export * from '../private/experiment-api/daily-dose.interface';
export * from '../private/experiment-api/survey-courses.interface';
export * from '../private/experiment-api/free-code-repos.interface';
export * from '../private/experiment-api/cr-progress-hint.interface';
export * from '../private/experiment-api/cc-comments.interface';
export * from '../private/experiment-api/user-guidance.interface';
export * from '../private/experiment-api/celebration-screen.interface';
export * from '../private/experiment-api/cr-comments.interface';
export * from '../private/experiment-api/cc-mandatory.interface';
export * from '../private/experiment-api/quiz-back-to-theory';
export * from '../private/experiment-api/experimental-courses.interface';
export * from '../private/experiment-api/lesson-quit-prompt.interface';
